<template>
  <div>
    <!--print doctor report pdf from this div -->
    <v-row style="margin: 10px">
      <v-col v-if="items.doctorName.length > 0" md="6" cols="12">
        <h6 v-if="items.doctorName.length == 1" style="line-height: 30px">
          پزشک: دکتر
          {{ items.doctorName[0] }}
        </h6>
        <h6 v-else>
          پزشکان:
          <span style="line-height: 30px">
            {{ items.doctorName.join(" - ") }}</span
          >
        </h6>
      </v-col>
      <v-col v-if="items.nurseName.length > 0" md="6" cols="12">
        <h6 v-if="items.nurseName.length == 1" style="line-height: 30px">
          پرستار: {{ items.nurseName[0] }}
        </h6>
        <h6 v-else>
          پرستاران:
          <span style="line-height: 30px">
            {{ items.nurseName.join(" - ") }}</span
          >
        </h6>
      </v-col>
      <v-col v-if="items.receptionName.length > 0">
        <h6 v-if="items.receptionName.length == 1" style="line-height: 30px">
          پذیرش: {{ items.receptionName[0] }}
        </h6>
        <h6 v-else>
          کارمندان پذیرش:
          <span style="line-height: 30px">
            {{ items.receptionName.join(" - ") }}</span
          >
        </h6>
      </v-col>
    </v-row>

    <v-row v-if="items.startDate" style="margin: 10px">
      <v-col
        cols="12"
        sm="12"
        :md="items.nurseName.length ? '4' : '6'"
        :lg="items.nurseName.length ? '4' : '6'"
        :xl="items.nurseName.length ? '4' : '6'"
      >
        <h6>
          از:
          {{ weekDict[moment(items.startDate, "jYYYY/jMM/jDD").weekday()] }}
          {{ items.startDate }} - {{ items.startTime }}
        </h6>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        :md="items.nurseName.length ? '4' : '6'"
        :lg="items.nurseName.length ? '4' : '6'"
        :xl="items.nurseName.length ? '4' : '6'"
      >
        <h6>
          تا:
          {{ weekDict[moment(items.endDate, "jYYYY/jMM/jDD").weekday()] }}
          {{ items.endDate }} - {{ items.endTime }}
        </h6>
      </v-col>
    </v-row>

    <v-row class="my-2 px-4">
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        v-if="items.totalCost != ''"
      >
        <div class="sum-div px-10 py-5">
          <div class="mt-3">
            <span
              class="font-weight-bold h2 primary--text"
              :class="isPrinting ? 'h5' : 'h2'"
            >
              جمع کارکرد درمانگاه:
            </span>
            <span
              class="h2 float-end"
              :class="isPrinting ? 'h5' : 'h2'"
              style="width: 35%"
            >
              {{ Number(items.totalCost).toLocaleString() }} ریال
            </span>
          </div>

          <div v-if="role != 'reception'" class="mt-3">
            <span
              class="h2 font-weight-bold primary--text"
              :class="isPrinting ? 'h5' : 'h2'"
            >
              جمع هزینه اقلام حفاظتی:
            </span>

            <span
              class="h2 float-end"
              style="width: 35%"
              :class="isPrinting ? 'h5' : 'h2'"
            >
              {{ Number(items.totalClinicShare).toLocaleString() }} ریال
            </span>
          </div>
          <div class="mt-3">
            <span
              class="font-weight-bold h2 primary--text"
              :class="isPrinting ? 'h5' : 'h2'"
            >
              جمع دریافتی:
            </span>
            <span
              class="float-end h2"
              style="width: 35%"
              :class="isPrinting ? 'h5' : 'h2'"
              >{{ Number(items.totalIncome).toLocaleString() }} ریال
            </span>
          </div>
        </div>
      </v-col>
      <v-col v-else class="text-center">
        <div>
          <h5 class="primary--text">گزارشی برای نمایش وجود ندارد.</h5>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        v-if="items.totalFinanceIncome"
      >
        <div class="sum-div px-10 py-5">
          <div class="mt-3">
            <span
              class="font-weight-bold h2 primary--text"
              :class="isPrinting ? 'h5' : 'h2'"
              >دریافتی کارتخوان:</span
            >
            <span
              class="float-end h2"
              :class="isPrinting ? 'h5' : 'h2'"
              style="width: 35%"
              >{{ Number(items.pos).toLocaleString() }} ریال
            </span>
          </div>
          <div class="mt-3">
            <span
              class="font-weight-bold h2 primary--text"
              :class="isPrinting ? 'h5' : 'h2'"
            >
              دریافتی نقدی:</span
            >
            <span
              class="float-end h2"
              :class="isPrinting ? 'h5' : 'h2'"
              style="width: 35%"
              >{{ Number(items.cash).toLocaleString() }} ریال
            </span>
          </div>
          <div class="mt-3" v-if="items.card">
            <span
              class="font-weight-bold h2 primary--text"
              :class="isPrinting ? 'h5' : 'h2'"
            >
              دریافتی کارت:</span
            >
            <span
              class="float-end h2"
              :class="isPrinting ? 'h5' : 'h2'"
              style="width: 35%"
              >{{ Number(items.card).toLocaleString() }} ریال
            </span>
          </div>
          <div class="mt-3" v-if="items.cheque">
            <span
              class="font-weight-bold h2 primary--text"
              :class="isPrinting ? 'h5' : 'h2'"
            >
              دریافتی چک:</span
            >
            <span
              class="float-end h2"
              :class="isPrinting ? 'h5' : 'h2'"
              style="width: 35%"
              >{{ Number(items.cheque).toLocaleString() }} ریال
            </span>
          </div>
          <div class="mt-3">
            <span
              class="font-weight-bold h2 primary--text"
              :class="isPrinting ? 'h5' : 'h2'"
            >
              جمع دریافتی کل:</span
            >
            <span
              class="float-end h2"
              :class="isPrinting ? 'h5' : 'h2'"
              style="width: 35%"
              >{{ Number(items.totalFinanceIncome).toLocaleString() }} ریال
            </span>
          </div>
          <div class="mt-3" v-if="job == 'reception'">
            <span
              class="font-weight-bold h2 primary--text"
              :class="isPrinting ? 'h5' : 'h2'"
            >
              جمع دریافتی آزمایشگاه:</span
            >
            <span
              class="float-end h2"
              :class="isPrinting ? 'h5' : 'h2'"
              style="width: 35%"
              >{{ Number(items.totalLabFinance).toLocaleString() }} ریال
            </span>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-expansion-panels background-color="transparent" multiple>
      <div class="break-avoid w-100" v-if="job == 'reception'">
        <v-expansion-panel
          class="pa-0 border mb-1 mt-2"
          v-if="
            items.allRelateShifts.relateShifts &&
            items.allRelateShifts.relateShifts.length
          "
        >
          <div class="break-avoid">
            <v-expansion-panel-header
              :style="
                isPrinting
                  ? 'background-color: none !important; border: none !important; font-size: 1.2rem !important;'
                  : ''
              "
            >
              <v-row>
                <v-col class="text-center primary--text">
                  <h5 class="mt-1 primary--text">
                    <strong>شیفت‌های مربوطه</strong>
                  </h5>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="my-1 pa-0">
              <v-row style="margin: 20px 10px">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-clock-outline</v-icon>
                      ساعت عملکرد :
                      {{ items.allRelateShifts.sum }}
                    </strong>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary"
                        >mdi-account-multiple-outline</v-icon
                      >
                      تعداد کل پذیرش :
                      {{
                        items.allRelateShifts.relateShifts
                          .map((x) => x.number)
                          .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                      }}
                    </strong>
                  </div>
                </v-col>
              </v-row>
              <mobile-text
                v-if="deviceType == 'mobile'"
                :title="'جدول شیفت‌های مربوطه'"
              />
              <div v-else>
                <!-- nurse report => shifts table -->
                <b-table
                  responsive
                  show-empty
                  :fields="recShiftFields"
                  :items="items.allRelateShifts.relateShifts"
                  empty-text="رکوردی برای نمایش وجود ندارد"
                  empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  :current-page="CurrentPage"
                  :per-page="PerPage"
                >
                  <template v-slot:head()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.label }}
                    </div>
                  </template>
                  <template v-slot:cell()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{
                        typeof data.value === "number"
                          ? Number(data.value).toLocaleString()
                          : data.value
                      }}
                    </div>
                  </template>
                  <template v-slot:cell(index)="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.index + 1 }}
                    </div>
                  </template>
                </b-table>
                <v-pagination
                  v-model="CurrentPage"
                  :length="Math.ceil(TotalRows / PerPage)"
                  :total-visible="5"
                  prev-icon="arrow_back"
                  next-icon="arrow_forward"
                  v-show="!isPrinting"
                ></v-pagination>
              </div>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </div>
      <div class="break-avoid w-100" v-if="job == 'nurse'">
        <v-expansion-panel
          class="pa-0 border mb-1 mt-2"
          v-if="
            items.allRelateShifts.relateShifts &&
            items.allRelateShifts.relateShifts.length
          "
        >
          <div class="break-avoid">
            <v-expansion-panel-header
              :style="
                isPrinting
                  ? 'background-color: none !important; border: none !important; font-size: 1.2rem !important;'
                  : ''
              "
            >
              <v-row>
                <v-col class="text-center primary--text">
                  <h5><strong>شیفت‌های مربوطه</strong></h5>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="my-1 pa-0">
              <v-row style="margin: 20px 10px">
                <v-col>
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-clock-outline</v-icon>
                      ساعت عملکرد :
                      {{ items.allRelateShifts.sum }}
                    </strong>
                  </div>
                </v-col>
              </v-row>
              <mobile-text
                v-if="deviceType == 'mobile'"
                :title="'جدول شیفت‌های مربوطه'"
              />

              <!-- nurse report => shifts table -->
              <div v-else>
                <b-table
                  responsive
                  show-empty
                  :fields="nurseShiftFields"
                  :items="items.allRelateShifts.relateShifts"
                  empty-text="رکوردی برای نمایش وجود ندارد"
                  empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  :per-page="nShiftPerPage"
                  :current-page="nShiftCurrentPage"
                >
                  <template v-slot:head()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.label }}
                    </div>
                  </template>
                  <template v-slot:cell()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{
                        typeof data.value === "number"
                          ? Number(data.value).toLocaleString()
                          : data.value
                      }}
                    </div>
                  </template>
                  <template v-slot:cell(index)="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.index + 1 }}
                    </div>
                  </template>
                </b-table>
                <v-pagination
                  v-model="nShiftCurrentPage"
                  :length="Math.ceil(nShiftTotalRows / nShiftPerPage)"
                  :total-visible="5"
                  prev-icon="arrow_back"
                  next-icon="arrow_forward"
                  v-show="!isPrinting"
                ></v-pagination>
              </div>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </div>
      <div class="break-avoid w-100" v-if="job != 'nurse'">
        <v-expansion-panel
          class="pa-0 border mb-1 mt-2"
          v-if="items.visits && items.visits.length"
        >
          <div class="break-avoid">
            <v-expansion-panel-header
              :style="
                isPrinting
                  ? 'background-color: none !important; border: none !important; font-size: 1.2rem !important;'
                  : ''
              "
            >
              <v-row>
                <v-col class="text-center primary--text">
                  <h5><strong>ویزیت</strong></h5>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="my-1 pa-0">
              <v-row style="margin: 20px 10px">
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-counter</v-icon>
                      تعداد :
                      {{ Number(items.visitTotalCount).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-hospital-building</v-icon>
                      کارکرد درمانگاه :
                      {{ Number(items.visitTotalTotal).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <v-col
                  v-if="role != 'reception'"
                  cols="12"
                  sm="6"
                  md="3"
                  lg="3"
                  xl="3"
                >
                  <div class="common-text common-div">
                    <v-icon color="primary">mdi-cash-multiple</v-icon>
                    <strong>
                      هزینه اقلام حفاظتی :
                      {{ Number(items.visitTotalClinic).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                <v-col
                  cols="12"
                  :sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-cash-plus</v-icon>
                      دریافتی :
                      {{ Number(items.visitTotalCosts).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
              </v-row>
              <mobile-text
                v-if="deviceType == 'mobile'"
                :title="'‌جدول ویزیت‌‌ها'"
              />
              <div v-else>
                <b-table
                  responsive
                  show-empty
                  :fields="visitFields"
                  :items="items.visits"
                  empty-text="رکوردی برای نمایش وجود ندارد"
                  empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  :per-page="visitPerPage"
                  :current-page="visitCurrentPage"
                >
                  <template v-slot:head()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.label }}
                    </div>
                  </template>
                  <template v-slot:cell()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{
                        typeof data.value === "number"
                          ? Number(data.value).toLocaleString()
                          : data.value
                      }}
                    </div>
                  </template>
                  <template v-slot:cell(index)="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.index + 1 }}
                    </div>
                  </template>
                </b-table>
                <v-pagination
                  v-model="visitCurrentPage"
                  :length="Math.ceil(visitTotalRows / visitPerPage)"
                  :total-visible="5"
                  prev-icon="arrow_back"
                  next-icon="arrow_forward"
                  v-show="!isPrinting"
                ></v-pagination>
              </div>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </div>
      <div class="break-avoid w-100" v-if="job != 'nurse' && items.takhasos">
        <v-expansion-panel
          class="pa-0 border mb-1 mt-2"
          v-if="items.takhasos && items.takhasos.length"
        >
          <div class="break-avoid">
            <v-expansion-panel-header
              :style="
                isPrinting
                  ? 'background-color: none !important; border: none !important; font-size: 1.2rem !important;'
                  : ''
              "
            >
              <v-row>
                <v-col class="text-center primary--text">
                  <h5>
                    <strong>ویزیت تخصصی</strong>
                  </h5>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="my-1 pa-0">
              <v-row style="margin: 20px 10px">
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-counter</v-icon>
                      تعداد :
                      {{ Number(items.takhasTotalCountVisit).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-hospital-building</v-icon>
                      کارکرد درمانگاه :
                      {{ Number(items.takhasTotalTotalVisit).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <v-col
                  v-if="role != 'reception'"
                  cols="12"
                  sm="6"
                  md="3"
                  lg="3"
                  xl="3"
                >
                  <div class="common-text common-div">
                    <v-icon color="primary">mdi-cash-multiple</v-icon>
                    <strong>
                      هزینه اقلام حفاظتی :
                      {{
                        Number(items.takhasTotalClinicVisit).toLocaleString()
                      }}
                    </strong>
                  </div>
                </v-col>
                <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-cash-plus</v-icon>
                      دریافتی :
                      {{ Number(items.takhasTotalCostsVisit).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
              </v-row>
              <mobile-text
                v-if="deviceType == 'mobile'"
                :title="'جدول ویزیت‌های تخصصی'"
              />
              <div v-else>
                <b-table
                  responsive
                  show-empty
                  :fields="visitFields"
                  :items="items.takhasos"
                  empty-text="رکوردی برای نمایش وجود ندارد"
                  empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  :per-page="takhasosPerPage"
                  :current-page="takhasosCurrentPage"
                >
                  <template v-slot:head()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.label }}
                    </div>
                  </template>
                  <template v-slot:cell()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{
                        typeof data.value === "number"
                          ? Number(data.value).toLocaleString()
                          : data.value
                      }}
                    </div>
                  </template>
                  <template v-slot:cell(index)="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.index + 1 }}
                    </div>
                  </template>
                </b-table>
                <v-pagination
                  v-model="takhasosCurrentPage"
                  :length="Math.ceil(takhasosTotalRows / takhasosPerPage)"
                  :total-visible="5"
                  prev-icon="arrow_back"
                  next-icon="arrow_forward"
                  v-show="!isPrinting"
                ></v-pagination>
              </div>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>

        <v-expansion-panel
          class="pa-0 border mb-1 mt-2"
          v-if="items.takhasosClinical && items.takhasosClinical.length"
        >
          <div class="break-avoid">
            <v-expansion-panel-header
              :style="
                isPrinting
                  ? 'background-color: none !important; border: none !important; font-size: 1.2rem !important;'
                  : ''
              "
            >
              <v-row>
                <v-col class="text-center primary--text">
                  <h5>
                    <strong>خدمات تخصصی</strong>
                  </h5>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="my-1 pa-0">
              <v-row style="margin: 20px 10px">
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-counter</v-icon>
                      تعداد :
                      {{
                        Number(items.takhasTotalCountClinical).toLocaleString()
                      }}
                    </strong>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-hospital-building</v-icon>
                      کارکرد درمانگاه :
                      {{
                        Number(items.takhasTotalTotalClinical).toLocaleString()
                      }}
                    </strong>
                  </div>
                </v-col>
                <v-col
                  v-if="role != 'reception'"
                  cols="12"
                  sm="6"
                  md="3"
                  lg="3"
                  xl="3"
                >
                  <div class="common-text common-div">
                    <v-icon color="primary">mdi-cash-multiple</v-icon>
                    <strong>
                      هزینه اقلام حفاظتی :
                      {{
                        Number(items.takhasTotalClinicClinical).toLocaleString()
                      }}
                    </strong>
                  </div>
                </v-col>
                <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-cash-plus</v-icon>
                      دریافتی :
                      {{
                        Number(items.takhasTotalCostsClinical).toLocaleString()
                      }}
                    </strong>
                  </div>
                </v-col>
              </v-row>
              <mobile-text
                v-if="deviceType == 'mobile'"
                :title="'جدول خدمات تخصصی'"
              />
              <div v-else>
                <b-table
                  responsive
                  show-empty
                  :fields="visitFields"
                  :items="items.takhasosClinical"
                  empty-text="رکوردی برای نمایش وجود ندارد"
                  empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  :per-page="takhasosClinicalPerPage"
                  :current-page="takhasosClinicalCurrentPage"
                >
                  <template v-slot:head()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.label }}
                    </div>
                  </template>
                  <template v-slot:cell()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{
                        typeof data.value === "number"
                          ? Number(data.value).toLocaleString()
                          : data.value
                      }}
                    </div>
                  </template>
                  <template v-slot:cell(index)="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.index + 1 }}
                    </div>
                  </template>
                </b-table>
                <v-pagination
                  v-model="takhasosClinicalCurrentPage"
                  :length="
                    Math.ceil(
                      takhasosClinicalTotalRows / takhasosClinicalPerPage
                    )
                  "
                  :total-visible="5"
                  prev-icon="arrow_back"
                  next-icon="arrow_forward"
                  v-show="!isPrinting"
                ></v-pagination>
              </div>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </div>
      <div
        class="break-avoid w-100"
        v-if="items.homeCare && items.homeCare.length"
      >
        <v-expansion-panel class="pa-0 border mb-1 mt-2">
          <div class="break-avoid">
            <v-expansion-panel-header
              :style="
                isPrinting
                  ? 'background-color: none !important; border: none !important; font-size: 1.2rem !important;'
                  : ''
              "
            >
              <v-row>
                <v-col class="text-center primary--text">
                  <h5><strong>خدمات در منزل</strong></h5>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="my-1 pa-0">
              <v-row style="margin: 20px 10px">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-counter</v-icon>
                      تعداد :
                      {{ Number(items.homeCareTotalCount).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-cash-plus</v-icon>
                      دریافتی :
                      {{ Number(items.homeCareTotalCost).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
              </v-row>
              <mobile-text
                v-if="deviceType == 'mobile'"
                :title="'جدول خدمات در منزل'"
              />
              <div v-else>
                <b-table
                  responsive
                  show-empty
                  :fields="otherItemsField"
                  :items="items.homeCare"
                  empty-text="رکوردی برای نمایش وجود ندارد"
                  empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  :current-page="homeCareCurrentPage"
                  :per-page="homeCarePerPage"
                >
                  <template v-slot:head()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.label }}
                    </div>
                  </template>
                  <template v-slot:cell()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{
                        typeof data.value === "number"
                          ? Number(data.value).toLocaleString()
                          : data.value
                      }}
                    </div>
                  </template>
                  <template v-slot:cell(index)="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.index + 1 }}
                    </div>
                  </template>
                </b-table>
                <v-pagination
                  v-model="homeCareCurrentPage"
                  :length="Math.ceil(homeCareTotalRows / homeCarePerPage)"
                  :total-visible="5"
                  prev-icon="arrow_back"
                  next-icon="arrow_forward"
                  v-show="!isPrinting"
                ></v-pagination>
              </div>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </div>
      <div
        class="break-avoid w-100"
        v-if="items.dentistry && items.dentistry.length"
      >
        <v-expansion-panel class="pa-0 border mb-1 mt-2">
          <div class="break-avoid">
            <v-expansion-panel-header
              :style="
                isPrinting
                  ? 'background-color: none !important; border: none !important; font-size: 1.2rem !important;'
                  : ''
              "
            >
              <v-row>
                <v-col class="text-center primary--text">
                  <h5><strong>خدمات دندانپزشکی </strong></h5>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="my-1 pa-0">
              <v-row style="margin: 20px 10px">
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'dentistryNurse' ? '3' : '4'"
                  :lg="role != 'dentistryNurse' ? '3' : '4'"
                  :xl="role != 'dentistryNurse' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-counter</v-icon>
                      تعداد :
                      {{ Number(items.dentistryTotalCount).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'dentistryNurse' ? '3' : '4'"
                  :lg="role != 'dentistryNurse' ? '3' : '4'"
                  :xl="role != 'dentistryNurse' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-hospital-building</v-icon>
                      کارکرد درمانگاه :
                      {{ Number(items.dentistryTotalTotal).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <v-col
                  v-if="role != 'dentistryNurse'"
                  cols="12"
                  sm="6"
                  md="3"
                  lg="3"
                  xl="3"
                >
                  <div class="common-text common-div">
                    <v-icon color="primary">mdi-cash-multiple</v-icon>
                    <strong>
                      هزینه اقلام حفاظتی :
                      {{ Number(items.dentistryTotalClinic).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{ Number(items.dentistryTotalExpenses).toLocaleString() }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                <v-col
                  cols="12"
                  :sm="6"
                  :md="role != 'dentistryNurse' ? '3' : '4'"
                  :lg="role != 'dentistryNurse' ? '3' : '4'"
                  :xl="role != 'dentistryNurse' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-cash-plus</v-icon>
                      دریافتی :
                      {{ Number(items.dentistryTotalCosts).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
              </v-row>
              <mobile-text
                v-if="deviceType == 'mobile'"
                :title="'جدول خدمات دندانپزشکی'"
              />
              <div v-else>
                <b-table
                  responsive
                  show-empty
                  :fields="visitFields"
                  :items="items.dentistry"
                  empty-text="رکوردی برای نمایش وجود ندارد"
                  empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  :current-page="dentistryCurrentPage"
                  :per-page="dentistryPerPage"
                >
                  <template v-slot:head()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.label }}
                    </div>
                  </template>
                  <template v-slot:cell()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{
                        typeof data.value === "number"
                          ? Number(data.value).toLocaleString()
                          : data.value
                      }}
                    </div>
                  </template>
                  <template v-slot:cell(index)="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.index + 1 }}
                    </div>
                  </template>
                </b-table>
                <v-pagination
                  v-model="dentistryCurrentPage"
                  :length="Math.ceil(dentistryTotalRows / dentistryPerPage)"
                  :total-visible="5"
                  prev-icon="arrow_back"
                  next-icon="arrow_forward"
                  v-show="!isPrinting"
                ></v-pagination>
              </div>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </div>
      <div
        class="break-avoid w-100"
        v-if="items.balinis && items.balinis.length"
      >
        <v-expansion-panel class="pa-0 border mb-1 mt-2">
          <div class="break-avoid">
            <v-expansion-panel-header
              :style="
                isPrinting
                  ? 'background-color: none !important; border: none !important; font-size: 1.2rem !important;'
                  : ''
              "
            >
              <v-row>
                <v-col class="text-center primary--text">
                  <h5><strong>خدمات پرستاری</strong></h5>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="my-1 pa-0">
              <v-row style="margin: 20px 10px">
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-counter</v-icon>
                      تعداد :
                      {{ Number(items.baliniTotalCount).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-hospital-building</v-icon>
                      کارکرد درمانگاه :
                      {{ Number(items.baliniTotalTotal).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <v-col
                  v-if="role != 'reception'"
                  cols="12"
                  sm="6"
                  md="3"
                  lg="3"
                  xl="3"
                >
                  <div class="common-text common-div">
                    <v-icon color="primary">mdi-cash-multiple</v-icon>
                    <strong>
                      هزینه اقلام حفاظتی :
                      {{ Number(items.baliniTotalClinic).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-cash-plus</v-icon>
                      دریافتی :
                      {{ Number(items.baliniTotalCosts).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
              </v-row>
              <mobile-text
                v-if="deviceType == 'mobile'"
                :title="'جدول خدمات پرستاری'"
              />
              <div v-else>
                <b-table
                  responsive
                  show-empty
                  :fields="visitFields"
                  :items="items.balinis"
                  empty-text="رکوردی برای نمایش وجود ندارد"
                  empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  :current-page="baliniCurrentPage"
                  :per-page="baliniPerPage"
                >
                  <template v-slot:head()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.label }}
                    </div>
                  </template>
                  <template v-slot:cell()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{
                        typeof data.value === "number"
                          ? Number(data.value).toLocaleString()
                          : data.value
                      }}
                    </div>
                  </template>
                  <template v-slot:cell(index)="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.index + 1 }}
                    </div>
                  </template>
                </b-table>
                <v-pagination
                  v-model="baliniCurrentPage"
                  :length="Math.ceil(baliniTotalRows / baliniPerPage)"
                  :total-visible="5"
                  prev-icon="arrow_back"
                  next-icon="arrow_forward"
                  v-show="!isPrinting"
                ></v-pagination>
              </div>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </div>
      <div
        class="break-avoid w-100"
        v-if="items.outBalinis && items.outBalinis.length"
      >
        <v-expansion-panel class="pa-0 border mb-1 mt-2">
          <div class="break-avoid">
            <v-expansion-panel-header
              :style="
                isPrinting
                  ? 'background-color: none !important; border: none !important; font-size: 1.2rem !important;'
                  : ''
              "
            >
              <v-row>
                <v-col class="text-center primary--text">
                  <h5><strong>خدمات پرستاری (خارج از درمانگاه)</strong></h5>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="my-1 pa-0">
              <v-row style="margin: 20px 10px">
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-counter</v-icon>
                      تعداد :
                      {{ Number(items.baliniOutTotalCount).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-hospital-building</v-icon>
                      کارکرد درمانگاه :
                      {{ Number(items.baliniOutTotalTotal).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <v-col
                  v-if="role != 'reception'"
                  cols="12"
                  sm="6"
                  md="3"
                  lg="3"
                  xl="3"
                >
                  <div class="common-text common-div">
                    <v-icon color="primary">mdi-cash-multiple</v-icon>
                    <strong>
                      هزینه اقلام حفاظتی :
                      {{ Number(items.baliniOutTotalClinic).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-cash-plus</v-icon>
                      دریافتی :
                      {{ Number(items.baliniOutTotalCosts).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
              </v-row>
              <mobile-text
                v-if="deviceType == 'mobile'"
                :title="'جدول خدمات'"
              />
              <div v-else>
                <b-table
                  responsive
                  show-empty
                  :fields="visitFields"
                  :items="items.outBalinis"
                  empty-text="رکوردی برای نمایش وجود ندارد"
                  empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  :current-page="outBaliniCurrentPage"
                  :per-page="outBaliniPerPage"
                >
                  <template v-slot:head()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.label }}
                    </div>
                  </template>
                  <template v-slot:cell()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{
                        typeof data.value === "number"
                          ? Number(data.value).toLocaleString()
                          : data.value
                      }}
                    </div>
                  </template>
                  <template v-slot:cell(index)="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.index + 1 }}
                    </div>
                  </template>
                </b-table>
                <v-pagination
                  v-model="outBaliniCurrentPage"
                  :length="Math.ceil(outBaliniTotalRows / outBaliniPerPage)"
                  :total-visible="5"
                  prev-icon="arrow_back"
                  next-icon="arrow_forward"
                  v-show="!isPrinting"
                ></v-pagination>
              </div>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </div>
      <div
        class="break-avoid w-100"
        v-if="items.surgery && items.surgery.length"
      >
        <v-expansion-panel class="pa-0 border mb-1 mt-2">
          <div class="break-avoid">
            <v-expansion-panel-header
              :style="
                isPrinting
                  ? 'background-color: none !important; border: none !important; font-size: 1.2rem !important;'
                  : ''
              "
            >
              <v-row>
                <v-col class="text-center primary--text">
                  <h5><strong>جراحی سرپایی </strong></h5>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="my-1 pa-0">
              <v-row style="margin: 20px 10px">
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-counter</v-icon>
                      تعداد :
                      {{ Number(items.surgeryTotalCount).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-hospital-building</v-icon>
                      کارکرد درمانگاه :
                      {{ Number(items.surgeryTotalTotal).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <v-col
                  v-if="role != 'reception'"
                  cols="12"
                  sm="6"
                  md="3"
                  lg="3"
                  xl="3"
                >
                  <div class="common-text common-div">
                    <v-icon color="primary">mdi-cash-multiple</v-icon>
                    <strong>
                      هزینه اقلام حفاظتی :
                      {{ Number(items.surgeryTotalClinic).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-cash-plus</v-icon>
                      دریافتی :
                      {{ Number(items.surgeryTotalCosts).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
              </v-row>
              <mobile-text
                v-if="deviceType == 'mobile'"
                :title="'جدول جراحی سرپایی'"
              />
              <div v-else>
                <b-table
                  responsive
                  show-empty
                  :fields="visitFields"
                  :items="items.surgery"
                  empty-text="رکوردی برای نمایش وجود ندارد"
                  empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  :current-page="surgeryCurrentPage"
                  :per-page="surgeryPerPage"
                >
                  <template v-slot:head()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.label }}
                    </div>
                  </template>
                  <template v-slot:cell()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{
                        typeof data.value === "number"
                          ? Number(data.value).toLocaleString()
                          : data.value
                      }}
                    </div>
                  </template>
                  <template v-slot:cell(index)="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.index + 1 }}
                    </div>
                  </template>
                </b-table>
                <v-pagination
                  v-model="surgeryCurrentPage"
                  :length="Math.ceil(surgeryTotalRows / surgeryPerPage)"
                  :total-visible="5"
                  prev-icon="arrow_back"
                  next-icon="arrow_forward"
                  v-show="!isPrinting"
                ></v-pagination>
              </div>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </div>
      <div
        class="break-avoid w-100"
        v-if="items.surgeryOut && items.surgeryOut.length"
      >
        <v-expansion-panel class="pa-0 border mb-1 mt-2">
          <div class="break-avoid">
            <v-expansion-panel-header
              :style="
                isPrinting
                  ? 'background-color: none !important; border: none !important; font-size: 1.2rem !important;'
                  : ''
              "
            >
              <v-row>
                <v-col class="text-center primary--text">
                  <h5><strong>جراحی سرپایی (خارج از درمانگاه)</strong></h5>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="my-1 pa-0">
              <v-row style="margin: 20px 10px">
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-counter</v-icon>
                      تعداد :
                      {{ Number(items.surgeryOutTotalCount).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-hospital-building</v-icon>
                      کارکرد درمانگاه :
                      {{ Number(items.surgeryOutTotalTotal).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <v-col
                  v-if="role != 'reception'"
                  cols="12"
                  sm="6"
                  md="3"
                  lg="3"
                  xl="3"
                >
                  <div class="common-text common-div">
                    <v-icon color="primary">mdi-cash-multiple</v-icon>
                    <strong>
                      هزینه اقلام حفاظتی :
                      {{ Number(items.surgeryOutTotalClinic).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-cash-plus</v-icon>
                      دریافتی :
                      {{ Number(items.surgeryOutTotalCosts).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
              </v-row>
              <mobile-text
                v-if="deviceType == 'mobile'"
                :title="'جدول جراحی سرپایی'"
              />
              <div v-else>
                <b-table
                  responsive
                  show-empty
                  :fields="visitFields"
                  :items="items.surgeryOut"
                  empty-text="رکوردی برای نمایش وجود ندارد"
                  empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  :current-page="surgeryOutCurrentPage"
                  :per-page="surgeryOutPerPage"
                >
                  <template v-slot:head()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.label }}
                    </div>
                  </template>
                  <template v-slot:cell()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{
                        typeof data.value === "number"
                          ? Number(data.value).toLocaleString()
                          : data.value
                      }}
                    </div>
                  </template>
                  <template v-slot:cell(index)="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.index + 1 }}
                    </div>
                  </template>
                </b-table>
                <v-pagination
                  v-model="surgeryOutCurrentPage"
                  :length="Math.ceil(surgeryOutTotalRows / surgeryOutPerPage)"
                  :total-visible="5"
                  prev-icon="arrow_back"
                  next-icon="arrow_forward"
                  v-show="!isPrinting"
                ></v-pagination>
              </div>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </div>
      <div class="break-avoid w-100" v-if="job != 'nurse'">
        <v-expansion-panel
          class="pa-0 border mb-1 mt-2"
          v-if="items.others && items.others.length"
        >
          <div class="break-avoid">
            <v-expansion-panel-header
              :style="
                isPrinting
                  ? 'background-color: none !important; border: none !important; font-size: 1.2rem !important;'
                  : ''
              "
            >
              <v-row>
                <v-col class="text-center primary--text">
                  <h5><strong>خدمات دیگر</strong></h5>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="my-1 pa-0">
              <v-row style="margin: 20px 10px">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-counter</v-icon>
                      تعداد :
                      {{ Number(items.otherTotalCount).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary">mdi-cash-plus</v-icon>
                      دریافتی :
                      {{ Number(items.otherTotalCost).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
              </v-row>
              <mobile-text
                v-if="deviceType == 'mobile'"
                :title="'جدول خدمات دیگر'"
              />
              <div v-else>
                <b-table
                  responsive
                  show-empty
                  :fields="otherItemsField"
                  :items="items.others"
                  empty-text="رکوردی برای نمایش وجود ندارد"
                  empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  :current-page="otherCurrentPage"
                  :per-page="otherPerPage"
                >
                  <template v-slot:head()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.label }}
                    </div>
                  </template>
                  <template v-slot:cell()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{
                        typeof data.value === "number"
                          ? Number(data.value).toLocaleString()
                          : data.value
                      }}
                    </div>
                  </template>
                  <template v-slot:cell(index)="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.index + 1 }}
                    </div>
                  </template>
                </b-table>
                <v-pagination
                  v-model="othersCurrentPage"
                  :length="Math.ceil(othersTotalRows / othersPerPage)"
                  :total-visible="5"
                  prev-icon="arrow_back"
                  next-icon="arrow_forward"
                  v-show="!isPrinting"
                ></v-pagination>
              </div>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </div>
      <div class="break-avoid w-100" v-if="job != 'nurse'">
        <v-expansion-panel
          class="pa-0 border mb-1 mt-2"
          v-if="items.canceled && items.canceled.length"
        >
          <div class="break-avoid">
            <v-expansion-panel-header
              :style="
                isPrinting
                  ? 'background-color: none !important; border: none !important; font-size: 1.2rem !important;'
                  : ''
              "
            >
              <v-row>
                <v-col class="text-center primary--text">
                  <h5 style="color: red"><strong>خدمات لغو شده</strong></h5>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="my-1 pa-0">
              <v-row style="margin: 20px 10px">
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div-red">
                    <strong>
                      <v-icon color="red">mdi-counter</v-icon>
                      تعداد :
                      {{ Number(items.canceledTotalCount).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div-red">
                    <strong>
                      <v-icon color="red">mdi-hospital-building</v-icon>
                      کارکرد درمانگاه :
                      {{ Number(items.canceledTotalTotal).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <v-col
                  v-if="role != 'reception'"
                  cols="12"
                  sm="6"
                  md="3"
                  lg="3"
                  xl="3"
                >
                  <div class="common-text common-div-red">
                    <v-icon color="red">mdi-cash-multiple</v-icon>
                    <strong>
                      هزینه اقلام حفاظتی :
                      {{ Number(items.canceledTotalClinic).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
                <!-- <v-col>
                                  <h6>
                                    <strong>
                                      هزینه مواد مصرفی :
                                      {{
                                        Number(
                                          shiftInfo.visitTotalExpenses
                                        ).toLocaleString()
                                      }}
                                    </strong>
                                  </h6>
                                </v-col> -->
                <v-col
                  cols="12"
                  sm="6"
                  :md="role != 'reception' ? '3' : '4'"
                  :lg="role != 'reception' ? '3' : '4'"
                  :xl="role != 'reception' ? '3' : '4'"
                >
                  <div class="common-text common-div-red">
                    <strong>
                      <v-icon color="red">mdi-cash-plus</v-icon>
                      دریافتی :
                      {{ Number(items.canceledTotalCost).toLocaleString() }}
                    </strong>
                  </div>
                </v-col>
              </v-row>
              <v-row style="margin: 20px 10px; color: red">
                <v-col>
                  <h5 class="primary--text">
                    <strong>مجموع : </strong>
                  </h5>
                </v-col>
                <v-col>
                  <h6>
                    <strong>
                      تعداد :
                      {{ Number(items.canceledTotalCount).toLocaleString() }}
                    </strong>
                  </h6>
                </v-col>
                <v-col>
                  <h6>
                    <strong>
                      کارکرد درمانگاه :
                      {{ Number(items.canceledTotalTotal).toLocaleString() }}
                    </strong>
                  </h6>
                </v-col>
                <v-col
                  v-if="role != 'reception'"
                  cols="12"
                  sm="6"
                  md="3"
                  lg="3"
                  xl="3"
                >
                  <h6>
                    <strong>
                      هزینه اقلام حفاظتی :
                      {{ Number(items.canceledTotalClinic).toLocaleString() }}
                    </strong>
                  </h6>
                </v-col>
                <!-- <v-col>
            <h6>
              <strong>
                هزینه مواد مصرفی :
                {{ Number(items.canceledTotalExpense).toLocaleString() }}
              </strong>
            </h6>
          </v-col> -->
                <v-col>
                  <h6>
                    <strong>
                      دریافتی :
                      {{ Number(items.canceledTotalCost).toLocaleString() }}
                    </strong>
                  </h6>
                </v-col>
              </v-row>
              <mobile-text
                v-if="deviceType == 'mobile'"
                :title="'جدول خدمات لغو شده'"
              />
              <div v-else>
                <b-table
                  responsive
                  show-empty
                  :fields="visitFields"
                  :items="items.canceled"
                  empty-text="رکوردی برای نمایش وجود ندارد"
                  empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  :per-page="canceledPerPage"
                  :current-page="canceledCurrentPage"
                >
                  <template v-slot:head()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.label }}
                    </div>
                  </template>
                  <template v-slot:cell()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{
                        typeof data.value === "number"
                          ? Number(data.value).toLocaleString()
                          : data.value
                      }}
                    </div>
                  </template>
                  <template v-slot:cell(index)="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.index + 1 }}
                    </div>
                  </template>
                </b-table>
                <v-pagination
                  v-model="canceledCurrentPage"
                  :length="Math.ceil(canceledTotalRows / canceledPerPage)"
                  :total-visible="5"
                  prev-icon="arrow_back"
                  next-icon="arrow_forward"
                  v-show="!isPrinting"
                ></v-pagination>
              </div>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </div>
      <div class="break-avoid w-100" v-if="job != 'nurse'">
        <v-expansion-panel
          class="pa-0 border mb-1 mt-2"
          v-if="items.labFinance && items.labFinance.length"
        >
          <div class="break-avoid">
            <v-expansion-panel-header
              :style="
                isPrinting
                  ? 'background-color: none !important; border: none !important; font-size: 1.2rem !important;'
                  : ''
              "
            >
              <v-row>
                <v-col class="text-center primary--text">
                  <h5><strong>مالی آزمایشگاه</strong></h5>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="my-1 pa-0">
              <v-row style="margin: 20px 10px">
                <div
                  class="common-text common-div my-2"
                  v-if="items.totalLabFinance"
                >
                  <strong>
                    <v-icon color="primary">mdi-cash-multiple</v-icon>
                    مجموع مالی:
                    {{ Number(items.totalLabFinance).toLocaleString() }}
                    ریال
                  </strong>
                </div></v-row
              >
              <mobile-text v-if="deviceType == 'mobile'" :title="'جدول مالی'" />
              <div v-else>
                <b-table
                  responsive
                  show-empty
                  :fields="labFields"
                  :items="items.labFinance"
                  empty-text="رکوردی برای نمایش وجود ندارد"
                  empty-filtered-text="رکوردی برای نمایش وجود ندارد"
                  :current-page="labCurrentPage"
                  :per-page="labPerPage"
                >
                  <template v-slot:head()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.label }}
                    </div>
                  </template>
                  <template v-slot:cell()="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{
                        typeof data.value === "number"
                          ? Number(data.value).toLocaleString()
                          : data.value
                      }}
                    </div>
                  </template>
                  <template v-slot:cell(index)="data">
                    <div style="text-align: center; vertical-align: middle">
                      {{ data.index + 1 }}
                    </div>
                  </template>
                </b-table>
                <v-pagination
                  v-model="labCurrentPage"
                  :length="Math.ceil(labTotalRows / labPerPage)"
                  :total-visible="5"
                  prev-icon="arrow_back"
                  next-icon="arrow_forward"
                  v-show="!isPrinting"
                ></v-pagination>
              </div>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </div>
    </v-expansion-panels>

    <hr v-show="isPrinting" />
    <v-row
      class="my-4 pa-2"
      v-show="isPrinting"
      v-if="items.totalCost != '' && items.totalFinanceIncome"
      style="break-inside: avoid"
    >
      <!--<v-col
        v-if="items.doctorName.length == 1"
        cols="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
      >
        <div
          class="px-3 py-4 rounded-xl"
          style="height: 200px; border: 2px solid #00a7b7"
        >
          <span class="font-weight-bold h4"> محل امضای پزشک: </span>
        </div>
      </v-col>-->
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <div
          class="px-3 py-4 rounded-xl"
          style="height: 200px; border: 2px solid #00a7b7"
        >
          <span class="font-weight-bold h4"> محل امضای پذیرش: </span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import moment from "moment-jalaali";
const MobileText = () => import("@/components/MobileText");
export default {
  props: ["items", "job", "isPrinting"],
  components: { MobileText },
  data() {
    return {
      moment: moment,
      labFields: [
        { key: "index", label: "#" },
        { key: "amount", label: "مقدار" },
        { key: "date", label: "تاریخ" },
        { key: "patientName", label: "نام بیمار" },
        { key: "transferType", label: "نحوه انتقال" },
        { key: "type", label: "نوع" },
      ],
      weekDict: [
        "شنبه",
        "یکشنبه",
        "دوشنبه",
        "سه شنبه",
        "چهارشنبه",
        "پنج شنبه",
        "جمعه",
      ],
      nurseShiftFields: [
        { key: "index", label: "#" },
        { key: "startTime", label: "تاریخ شیفت" },
        { key: "duration", label: "ساعت عملکرد" },
      ],
      recShiftFields: [
        { key: "index", label: "#" },
        { key: "startTime", label: "تاریخ شیفت" },
        { key: "duration", label: "ساعت عملکرد" },
        { key: "number", label: "تعداد پذیرش " },
      ],
      otherItemsField: [
        { key: "index", label: "#" },
        { key: "name", label: "خدمت" },
        { key: "count", label: "تعداد" },
        { key: "totalCost", label: "مبلغ دریافتی" },
      ],
      role: "",
      CurrentPage: 1,
      TotalRows: "",
      PerPage: 10,
      nShiftCurrentPage: 1,
      nShiftTotalRows: "",
      nShiftPerPage: 10,
      baliniCurrentPage: 1,
      baliniTotalRows: "",
      baliniPerPage: 10,
      outBaliniCurrentPage: 1,
      outBaliniTotalRows: "",
      outBaliniPerPage: 10,
      surgeryCurrentPage: 1,
      surgeryTotalRows: "",
      surgeryPerPage: 10,
      surgeryOutCurrentPage: 1,
      surgeryOutTotalRows: "",
      surgeryOutPerPage: 10,
      dentistryCurrentPage: 1,
      dentistryTotalRows: "",
      dentistryPerPage: 10,
      homeCareCurrentPage: 1,
      homeCareTotalRows: "",
      homeCarePerPage: 10,
      visitCurrentPage: 1,
      visitTotalRows: "",
      visitPerPage: 10,
      takhasosCurrentPage: 1,
      takhasosTotalRows: "",
      takhasosPerPage: 10,
      takhasosClinicalCurrentPage: 1,
      takhasosClinicalTotalRows: "",
      takhasosClinicalPerPage: 10,
      othersCurrentPage: 1,
      othersTotalRows: "",
      othersPerPage: 10,
      canceledCurrentPage: 1,
      canceledTotalRows: "",
      canceledPerPage: 10,
      labCurrentPage: 1,
      labTotalRows: "",
      labPerPage: 10,
    };
  },
  computed: {
    visitFields() {
      let fields =
        this.role == "reception"
          ? [
              { key: "index", label: "#" },
              { key: "name", label: "خدمت" },
              { key: "count", label: "تعداد" },
              { key: "total", label: "کارکرد درمانگاه" },
              // { key: "totalExpens", label: "هزینه مواد مصرفی" },
              { key: "totalCost", label: "مبلغ دریافتی" },
            ]
          : [
              { key: "index", label: "#" },
              { key: "name", label: "خدمت" },
              { key: "count", label: "تعداد" },
              { key: "total", label: "کارکرد درمانگاه" },
              { key: "totalClinicShare", label: "هزینه اقلام حفاظتی" },
              // { key: "totalExpens", label: "هزینه مواد مصرفی" },
              { key: "totalCost", label: "مبلغ دریافتی" },
            ];
      return fields;
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");

    this.TotalRows = this.items.allRelateShifts.relateShifts
      ? this.items.allRelateShifts.relateShifts.length
      : 0;
    this.nShiftTotalRows = this.items.allRelateShifts.relateShifts
      ? this.items.allRelateShifts.relateShifts.length
      : 0;
    this.baliniTotalRows = this.items.balinis ? this.items.balinis.length : 0;
    this.outBaliniTotalRows = this.items.outBalinis
      ? this.items.outBalinis.length
      : 0;
    this.surgeryTotalRows = this.items.surgery ? this.items.surgery.length : 0;
    this.surgeryOutTotalRows = this.items.surgeryOut
      ? this.items.surgeryOut.length
      : 0;
    this.dentistryTotalRows = this.items.dentistry
      ? this.items.dentistry.length
      : 0;
    this.homeCareTotalRows = this.items.homeCare
      ? this.items.homeCare.length
      : 0;
    this.visitTotalRows = this.items.visits ? this.items.visits.length : 0;
    this.takhasosTotalRows = this.items.takhasos
      ? this.items.takhasos.length
      : 0;
    this.takhasosClinicalTotalRows = this.items.takhasosClinical
      ? this.items.takhasosClinical.length
      : 0;
    this.otherTotalRows = this.items.others ? this.items.others.length : 0;
    this.otherTotalRows = this.items.others ? this.items.others.length : 0;
    this.canceledTotalRows = this.items.canceled
      ? this.items.canceled.length
      : 0;
    this.labTotalRows = this.items.labFinance
      ? this.items.labFinance.length
      : 0;
  },
};
</script>
<style lang="scss">
@media (max-width: 600px) {
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
}
</style>
